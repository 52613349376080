const base = "/api/tournament/per-diem";

class PerDiemService {
	async getPerDiem({ status }) {
		const { data } = await axios.get(`${base}`, {
			params: { status },
		});
		return data;
	}
	async createPerDiem({ name }) {
		try {
			const { data } = await axios.post(`${base}`, {
				name,
			});
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async editPerDiem({ id, status, name }) {
		try {
			const data = await axios.put(`${base}/${id}`, {
				name,
				status,
			});
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
	async deletePerDiem({ id }) {
		try {
			const { data } = await axios.delete(`${base}/${id}`);
			return data;
		} catch (error) {
			return error.response.data;
		}
	}
}

export default new PerDiemService();

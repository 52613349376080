<template>
	<div>
		<b-overlay :show="isLoading">
			<b-card class="p-1" no-body>
				<b-row>
					<b-col class="d-flex justify-content-end">
						<b-button
							class="d-inline text-nowrap"
							variant="primary"
							@click="creationModal.show = !creationModal.show"
						>
							Agregar viatico
						</b-button>
					</b-col>
				</b-row>
			</b-card>
			<b-card no-body>
				<b-table-simple sticky-header="60vh">
					<b-thead class="text-center">
						<b-tr>
							<b-th>Nombre</b-th>
							<b-th>Estado</b-th>
							<b-th>Creado por</b-th>
							<b-th>Acciones</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<b-tr v-for="row in rows" :key="row.id">
							<b-td>{{ row.name }}</b-td>
							<b-td>
								<b-badge :variant="row.status_id == 1 ? 'success' : 'danger'">{{ row.status }}</b-badge>
							</b-td>
							<b-td>
								{{ row.created_by.user }}
								<br />
								<small>{{ row.created_by.date | myGlobalDayShort }}</small>
							</b-td>
							<b-td>
								<div class="d-flex justify-content-center" style="gap: 0.5rem">
									<b-button size="sm" variant="flat-warning" @click="edit(row)">
										<feather-icon icon="EditIcon" />
									</b-button>
									<b-button size="sm" variant="flat-danger" @click="remove(row)">
										<feather-icon icon="TrashIcon" />
									</b-button>
								</div>
							</b-td>
						</b-tr>
						<b-tr v-if="rows.length == 0">
							<b-td colspan="4">
								<p class="mb-0">No hay registros por mostrar</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</b-card>
		</b-overlay>
		<b-modal centered v-model="creationModal.show" title="Crear nuevo viático" @hidden="handleHidden">
			<b-overlay :show="creationModal.loading">
				<validation-observer ref="form">
					<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
						<b-form-group id="fieldset-1" label="Nombre" label-for="input-1">
							<b-form-input
								:invalid-feedback="errors[0]"
								:state="errors[0] ? false : null"
								placeholder="Ingrese nombre del viático"
								v-model="creationModal.name"
							/>
						</b-form-group>
						<small class="text-danger">{{ errors[0] }}</small>
					</ValidationProvider>
				</validation-observer>
				<b-form-group v-if="creationModal.id" id="fieldset-1" label="Estado" label-for="input-1">
					<v-select
						label="name"
						:options="arrayStatus"
						:reduce="(opt) => opt.id"
						v-model="creationModal.status"
						placeholder="Seleccionar estado"
						class="w-100"
					/>
				</b-form-group>
			</b-overlay>
			<template #modal-footer>
				<b-button variant="success" @click="create">Guardar</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import perDiemService from "@/services/tournament/perDiem.service"

export default {
	props: {
		action: String,
	},
	data: () => ({
		isLoading: false,
		rows: [],
		creationModal: {
			show: false,
			loading: false,
			name: "",
			id: null,
			status: null,
		},
		arrayStatus: [
			{
				id: 1,
				name: "activo",
			},
			{
				id: 2,
				name: "inactivo",
			},
		],
	}),
	methods: {
		handleHidden() {
			this.creationModal = { show: false, loading: false }
			this.$emit("actionDone")
		},
		async getRows() {
			this.isLoading = true
			const { data } = await perDiemService.getPerDiem({ status: null })
			this.rows = data
			this.isLoading = false
		},
		async create() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				)
				return
			}
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			console.log("sd", this.creationModal)
			this.isPreloading()

			if (this.creationModal.id) {
				const { message } = await perDiemService.editPerDiem({
					name: this.creationModal.name,
					id: this.creationModal.id,
					status: this.creationModal.status,
				})
				this.showToast("success", "top-right", "Corrales", "SuccessIcon", message)
			} else {
				const { message } = await perDiemService.createPerDiem({
					name: this.creationModal.name,
				})
				this.showToast("success", "top-right", "Corrales", "SuccessIcon", message)
			}

			await this.getRows()
			this.handleHidden()
			this.isPreloading(false)
		},
		async edit(row) {
			this.creationModal = {
				show: true,
				loading: false,
				name: row.name,
				id: row.id,
				status: row.status == "activo" ? 1 : 2,
			}
		},
		async remove(row) {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			console.log("row", row)
			this.isPreloading()
			const { message, status } = await perDiemService.deletePerDiem({ id: row.id })
			if (status) {
				this.showToast("success", "top-right", "Corrales", "SuccessIcon", message)
			}
			await this.getRows()
			this.isPreloading(false)
		},
	},
	created() {
		this.getRows()
	},
	watch: {
		action(val) {
			if (val == "create-quality") {
				this.creationModal.active = !this.creationModal.active
			}
		},
	},
}
</script>
